<template>
    <div>
        <div style="padding:10px 16px">
            <div class="content">
                <div class="contentText"> 武汉康圣贝泰生物科技有限公司（以下简称：康圣贝泰）请您务必仔细阅读以下内容，并在确认充分理解与同意后使用：
                </div>
                <div class="contentText"> 1、请确认您本人对本评测产品有充分合理的认知（包括检测方法的原理和局限性、样本采集的方法和注意事项、预期目的、潜在风险和必要性等），以免造成不必要的检测或心理恐慌。
                </div>
                <div class="contentText"> 2、本评测结果，不能作为临床诊断依据，如需确诊请前往医院进行咨询。受检者因检测结果而引起的精神负担，康圣贝泰不就此承担责任。
                </div>
                <div class="contentText"> 3、由于检测方法存在一定的局限性，本检测无法完全排除假阴性或假阳性结果的出现。
                </div>
                <div class="contentText"> 4、康圣贝泰对本次受检样本在康圣贝泰实验室使用的检测方法得到的结果负责。受检者须对提供的样本及样本信息的真实性、准确性、合法性负责。
                </div>
                <div class="contentText"> 5、由于个体差异，可能会发生样本采集失败而需要重新采样情况，在此情况下需要您的配合，康圣贝泰可免费安排第二次采样。
                </div>
                <div class="contentText">
                    6、检测结果属于个人隐私，康圣贝泰将对检测结果保密并依法保护受检者隐私。您亦应妥善保管，以免资料泄露而可能对个人造成不利影响。因您的原因所造成的个人信息外泄，康圣贝泰不承担责任。
                </div>
                <div class="contentText"> 7、为检测研究所需，您授权康圣贝泰对本检测涉及的样本及数据进行处置，包括原始样本、DNA等。
                </div>
                <div class="contentText"> 8、康圣贝泰对检测结果保留解释的权利。
                </div>
                <div class="contentText"> 参与测评即表明您充分知悉并同意本《用户使用须知》的所有内容。
                </div>
            </div>

        </div>
        <!-- 可读+未同意————可以点击 -->
        <div v-if="!isReadonly && !isAgree">
            <div style="height:92px;width:100%;"></div>
            <div class="bottom">
                <button class="bottomButton" @click="agree">已阅读并同意 {{ index != 0 ? index + 's' : '' }}</button>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {
            index: 5,  //5s后能点击同意按钮
            isAgree: localStorage.getItem('userRights') == '1' ? true : false,
            isReadonly: localStorage.getItem('isReadonly') == 'false' ? false : true
        }
    },
    created() {
    },
    mounted() {
        this.timeOut()
    },
    computed: {
    },
    methods: {
        // 倒计时
        timeOut() {
            const timer = setInterval(() => {
                if (this.index > 0) {
                    this.index--
                } else {
                    clearInterval(timer)
                }
            }, 1000)
        },
        // 同意知情书
        agree() {
            if (this.index == 0) {
                localStorage.setItem('userRights', 1)
                // 返回上一页
                this.$router.back()
            }
        }
    },
}
</script>
<style lang="scss" scoped>
.content {
    text-align: justify;
    
}

.contentText {
    font-size: 14px;
    color: #333333;
    text-align: justify;
    margin-bottom: 10px;
}
</style>
